<template>
    
    
  
      
  
      <div >
        <main class="relative shell_primaryContent__Aoh08" id="primary-content">
          <div>
            <div
              class="sb-contentColumn sb-global-gutters mx-auto sb-contentColumn--xlarge px0 my5"
            >
              <div class="sb-contentColumn__inner">
                <div class="md-flex">
                  <div class="adpageimage flex justify-center items-center "
                    style="background-color: #f4c5a1"
                  >
                    <img
                      alt="目标树帮你梳理你的思路，组织你的项目与任务清单"
                      class="block goaltreeimg sizeimg userimg"
                      src="https://images-1255367272.cos.ap-chengdu.myqcloud.com/newgoaltree.png"
                    />
                  </div>
                  <div
                    class="py5 flex items-center justify-center text-center md-size6of12 textBlockGutter"
                    style="background-color: #f4c5a1"
                  >
                    <div class="oddcontent imgcontent">
                      <h2 class="infogrouptitle">
                        <span class="infotitle"
                          >{{ $t('goaltreetext') }}</span
                        >
                      </h2>
                      <div class="">
                        <ul class="blackfont">
                          <li>{{ $t('goaltreefun1') }}</li>
                          <li>{{ $t('goaltreefun2') }}</li>
                        </ul>
                      </div>
                      <a class="userbutton" @click="showusersystemfunc"
                        >{{ $t('try') }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              class="sb-contentColumn sb-global-gutters mx-auto sb-contentColumn--xlarge px0 my5"
              id="137-82986"
            >
              <div class="sb-contentColumn__inner">
                <div class="md-flex row-reverse">
                  <div
                    class="adpageimage flex justify-center items-center "
                    style="background-color: #d4e9e2"
                  >
                    <img
                    alt="任务树帮助你不断拆解你的复杂任务，克服情绪上的障碍"
                      class="block imagetasktree sizeimg userimg"
                      src="https://images-1255367272.cos.ap-chengdu.myqcloud.com/tasktree.png"
                    />
                  </div>
                  <div
                    class="py5 flex items-center flex justify-center md-size6of12 textBlockGutter"
                    style="background-color: #d4e9e2"
                  >
                    <div class="evencontent imgcontent">
                      <h1
                        class="infogrouptitle mb4 md text-semibold"
                        tabindex="-1"
                        style="color: #1e3932"
                      >
                        <span class="infotitle"
                          >{{ $t('tasktreetext') }}</span
                        >
                      </h1>
                      <div style="color: #1e3932">
                        <ul class="blackfont">
                          <li>{{ $t('tasktreefun1') }}</li>
                          <li>{{ $t('tasktreefun2') }}</li>
                          <li>{{ $t('tasktreefun3') }}</li>
                          <li>{{ $t('tasktreefun4') }}</li>
                        </ul>
                      </div>
                      <a class="userbutton" @click="showusersystemfunc"
                        >{{ $t('try') }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              class="sb-contentColumn sb-global-gutters mx-auto sb-contentColumn--xlarge px0 my5"
              id="137-82561"
            >
              <div class="sb-contentColumn__inner">
                <div class="md-flex image-content-block_flexRowReverse__AEmTi">
                  <div
                    class="adpageimage   flex justify-center items-center "
                    style="background-color: rgb(210 210 241);"
                  >
                    <img
                    alt="评价算法帮助你获得实时反馈，获得奇妙的心流体验"
                      class="block smallimg sizeimg userimg"
                      src="https://rongbrain-1255367272.cos.ap-chengdu.myqcloud.com/evasyslarge.png"
                    />
                  </div>
                  <div
                    class="py5 flex items-center justify-center text-center md-size6of12 textBlockGutter"
                    style="background-color: rgb(210 210 241)"
                  >
                    <div class="imgcontent">
                      <h2
                        class="infogrouptitle mb4 xl text-semibold"
                        tabindex="-1"
                        style="color: #ffffff"
                      >
                        <span
                          class="rich-text_text__02jRf rich-text_width__0GHdb rich-text_greenLinks__BNG4d infotitle"
                          >{{ $t('evasystext') }}</span
                        >
                      </h2>
                      <div
                        class="md rich-text_text__02jRf rich-text_width__0GHdb rich-text_greenLinks__BNG4d blackfont"
                      >
                      {{ $t('evasysfunc1') }}
                        <ul>
                          <li>  {{ $t('evasysfunc2') }}</li>
                          <li> {{ $t('evasysfunc3') }}</li>
                          <li> {{ $t('evasysfunc4') }}</li>
                        </ul>
                      </div>
  
                      <a
                        class="userbutton"
                        style="min-width: 35px; min-height: 35px"
                        @click="showusersystemfunc"
                        >{{ $t('try') }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div>
            <div
              class="sb-contentColumn sb-global-gutters mx-auto sb-contentColumn--xlarge px0 my5"
              id="137-82563"
            >
              <div class="sb-contentColumn__inner">
                <div
                  class="md-flex row-reverse image-content-block_flexRowReverse__AEmTi"
                >
                  <div
                    class="adpageimage flex justify-center items-center "
                    style="background-color: #a3bc97"
                  >
                    <img
                    alt="我们关注你的隐私，绝不泄露，对商业广告说NO!"
                      class="block smallimg sizeimg userimg"
                      src="https://images-1255367272.cos.ap-chengdu.myqcloud.com/privacy.jpg"
                    />
                  </div>
                  <div
                    class="py5 flex items-center justify-center text-center md-size6of12 textBlockGutter"
                    style="background-color: #a3bc97"
                  >
                    <div class="imgcontent">
                      <h2
                        class="infogrouptitle mb4 xl text-semibold"
                        tabindex="-1"
                        style="color: #ffffff"
                      >
                        <span
                          class="rich-text_text__02jRf rich-text_width__0GHdb rich-text_greenLinks__BNG4d infotitle"
                          > {{ $t('privacytext') }}</span
                        >
                      </h2>
                      <div
                        class="md rich-text_text__02jRf rich-text_width__0GHdb rich-text_greenLinks__BNG4d blackfont"
                      >
                        <ul>
                          <li>{{ $t('privacyfunc1') }}</li>
                          <li>{{ $t('privacyfunc2') }}</li>
                          <li>{{ $t('privacyfunc3') }}</li>
                        </ul>
                      </div>
                      <a class="userbutton" @click="showusersystemfunc"
                        >{{ $t('try') }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div
              class="sb-contentColumn sb-global-gutters mx-auto sb-contentColumn--xlarge px0 my5"
              id="137-82561"
            >
              <div class="sb-contentColumn__inner">
                <div class="md-flex image-content-block_flexRowReverse__AEmTi">
                  <div
                    class="adpageimage   flex justify-center items-center "
                    style="background-color: rgb(210 210 241);"
                  >
                    <img
                    alt="评价算法帮助你获得实时反馈，获得奇妙的心流体验"
                      class="block smallimg sizeimg userimg"
                 
                      src="https://rongbrain-1255367272.cos.ap-chengdu.myqcloud.com/recommendalgorithm.jpg"
                    />
                  </div>
                  <div
                    class="py5 flex items-center justify-center text-center md-size6of12 textBlockGutter"
                    style="background-color: rgb(210 210 241)"
                  >
                    <div class="imgcontent">
                      <h2
                        class="infogrouptitle mb4 xl text-semibold"
                        tabindex="-1"
                        style="color: #ffffff"
                      >
                        <span
                          class="rich-text_text__02jRf rich-text_width__0GHdb rich-text_greenLinks__BNG4d infotitle"
                          >{{ $t('recmtext') }}</span
                        >
                      </h2>
                      <div
                        class="md rich-text_text__02jRf rich-text_width__0GHdb rich-text_greenLinks__BNG4d blackfont"
                      >
                    
                        <ul>
                          <li>  {{ $t('recmfunc1') }}</li>
                          <li> {{ $t('recmfunc2') }}</li>
                          <li> {{ $t('recmfunc3') }}</li>
                        </ul>
                      </div>
  
                      <a
                        class="userbutton"
                        style="min-width: 35px; min-height: 35px"
                        @click="showusersystemfunc"
                        >{{ $t('try') }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div>
            <div
              class="sb-contentColumn sb-global-gutters mx-auto sb-contentColumn--xlarge px0 my5"
              id="137-82563"
            >
              <div class="sb-contentColumn__inner">
                <div
                  class="md-flex row-reverse image-content-block_flexRowReverse__AEmTi"
                >
                  <div
                    class="adpageimage flex justify-center items-center "
                    style="background-color: #a3bc97"
                  >
                    <img
                  
                      class="block smallimg sizeimg userimg"
                      src="https://rongbrain-1255367272.cos.ap-chengdu.myqcloud.com/storybook.png"
                    />
                  </div>
                  <div
                    class="py5 flex items-center justify-center text-center md-size6of12 textBlockGutter"
                    style="background-color: #a3bc97"
                  >
                    <div class="imgcontent">
                      <h2
                        class="infogrouptitle mb4 xl text-semibold"
                        tabindex="-1"
                        style="color: #ffffff"
                      >
                        <span
                          class="rich-text_text__02jRf rich-text_width__0GHdb rich-text_greenLinks__BNG4d infotitle"
                          > {{ $t('dairytext') }}</span
                        >
                      </h2>
                      <div
                        class="md rich-text_text__02jRf rich-text_width__0GHdb rich-text_greenLinks__BNG4d blackfont"
                      >
                        <ul>
                          <li>{{ $t('dairyfunc1') }}</li>
                          <li>{{ $t('dairyfunc2') }}</li>
                          <li>{{ $t('dairyfunc3') }}</li>
                        </ul>
                      </div>
                      <a class="userbutton" @click="showusersystemfunc"
                        >{{ $t('try') }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          
  <div class="adzone">
   <h3 class="font-bold text-center " data-aos="fade-up">
  atomTask已经帮助用户解决
  <br>
  <a class="adnum">40,000+</a> 的日常任务，共在任务上投入<a class="adnum1">23360+</a> 小时
  </h3>
  
  <p    class="introtext " data-aos="fade-up">
    {{ $t('declaim') }}
  </p>
         
  </div>
        </main>
       
      </div>
  
  </template>
  
  <script>
  import axios from "axios";
  // import * from "../configure/index.js"
  import configure_options from "../configure";
//   import agreezone from "./softwareprotocal.vue"
  import { defineComponent, ref } from "vue";
  // axios.defaults.withCredentials = true
  export default defineComponent({
    name: "HelloWorld",
    props: {
      msg: String,
    },
    components:{
      // Taskszone,
    //   agreezone
    },
    data() {},
    setup(props, context) {
      console.log(props, context);
  
      return {
        showimage:ref(true),
        showagreement:ref(false),
        checked:ref(false),
        showusersystem: ref(false),
        loginemail: ref(""),
        loginpassword: ref(""),
        registername: ref(""),
        registeremail: ref(""),
        registerpassword: ref(""),
        repeatpassword: ref(""),
        verifycode:ref(""),
        resetemail: ref(""),
        resetpassword: ref(""),
        resetverifycode:ref(""),
        repeatresetpassword: ref(""),
        showlogin: ref(true),
        showregister:ref(true),
        showreset:ref(true),
      };
    },
    methods: {
  
      getemailverifycode(){
        var env = configure_options["env"];
        var serverhost = configure_options["app_setting"][env]["serverhost"];
        // alert(this.registername)
        if (this.registeremail == "" ||this.registername=="") {
          alert("用户名,邮件,验证码与密码不能为空");
          return;
        }
        console.log(configure_options["app_setting"][env]);
      
        var userinfo = {
          username: this.registername,
          email: this.registeremail,
          client: "clientv2web",
        };
     
        axios
          .post(serverhost + "/v1/emailverify", userinfo)
          .then((response) => {
            alert("邮件已发送,请填写验证码");
            console.log(response);
            // this.$router.push("/taskmanager")
          })
          .catch((e) => {
            //这里可以填入各种邮件认证出现的问题
            alert("获取验证码异常，请联系管理员")
           e.preventDefault();
          });
        
      },
  
      getResetemailrverifycode(){
  
    // alert("点击了重设密码部分")
  
     var email = this.resetemail
     var verifycode = ""
     var env = configure_options["env"];
    var serverhost = configure_options["app_setting"][env]["serverhost"];
    //  var confirmpassword = login_info[2].value
    // console.log("点击了获取验证码部分")
    console.log(email);
     console.log(verifycode);
     if(email==""){
     alert("请补全邮件地址哟")
   
     return 0;
     }
     var username = "empty"
     var account_info = {
        'email':email,
        'username':username,
        'client':'web'
     }
     console.log(account_info)
    //注意这里使用的了nginx的反向代理
     var verifyurl
     verifyurl = serverhost + `/v1/emailverify`
  
     axios.post(verifyurl,account_info )
     .then(response => {
  
  
  
       var serverdata = response.data
      //  print("response","blue")
       console.log(response)
      //  print("reponse头部","green")
       console.log(response.headers)
      //  print("reponse头部-setcookie","green")
       console.log(response.headers['set-cookie'])
      //  print("打印用户系统返回的东西","blue")
       console.log(serverdata)
  
       alert("邮件验证码发送成功")
   
     })
     .catch(e => {
     
      if (e.response.status==455){
       var data=  e.response.data
  
      var error_string =""
      
      for (const key in data) {
        // console.log(`${key}: ${reviewalgo_covert[key]}`);data.push(reviewalgo_covert[key])
    
       error_string += key+":"+ data[key]+" "
      }
      alert(error_string)
      }else if(e.response.status==422){
        alert("服务异常请稍后再试")
      
      }else{
        alert("账户异常，请联系管理员")
      }
  
     })  
  }
  ,
  
  
  
      showresetfunc(){
      this.showlogin = false;
      this.showregister = false;
      this.showreset = true;
      },
      showusersystemfunc() {
        this.showusersystem = true;
        this.$emit('setTime',new Date().getTime())
        document.getElementById("userpage").style.backgroundColor = "#d9e9d1";
      },
  
      showfeaturesfunc() {
        this.showusersystem = false;
        document.getElementById("userpage").style.backgroundColor = 'white';
      },
  
  
  
      showagreezone(){
  
        this.showimage=false;
        this.showagreement =true;
      },
  
      showimagezone(){
  
  this.showimage=true;
  this.showagreement =false;
  },
  
  
      godoc() {
        this.$router.push("/docweb");
      },
      tologin() {
        console.log("服务器配置读取");
        console.log(configure_options);
        var env = configure_options["env"];
  
        console.log(configure_options["app_setting"][env]);
        var serverhost = configure_options["app_setting"][env]["serverhost"];
  
        var userinfo = {
          email: this.loginemail,
          password: this.loginpassword,
          clent: "web",
        };
        if (this.loginemail == "" || this.loginpassword == "") {
          alert("用户名与密码不能为空");
          return;
        }
  
        axios
          .post(serverhost + "/v1/login", userinfo)
          .then((response) => {
            alert("登录成功");
            console.log(response);
            if (response.data.mode == "travel") {
              this.$router.push("/travel");
            } else {
              this.$router.push("/taskmanager");
            }
          })
          .catch((error) => {
            if (error.response.status == 423) {
              console.log(error);
              alert("请联系管理员激活");
            } else {
              alert("用户名或密码错误");
            }
          });
      },
      toregister() {
        console.log("服务器配置读取");
        console.log(configure_options);
  
        console.log("服务器配置读取");
        console.log(configure_options);
        var env = configure_options["env"];
        // alert(this.registername)
        if (this.registeremail == "" || this.registerpassword == ""||this.registername==""||this.verifycode=="") {
          alert("用户名,邮件与密码不能为空");
          return;
        }
  
        console.log(configure_options["app_setting"][env]);
        var serverhost = configure_options["app_setting"][env]["serverhost"];
  
        var userinfo = {
          username: this.registername,
          email: this.registeremail,
          password: this.registerpassword,
          verifycode:this.verifycode,
          client: "clientv2",
              
        };
  
        if (this.repeatpassword != this.registerpassword) {
          alert("两次输入密码不一致");
          return;
        }
  
        if(this.checked==false){
          alert("请同意软件使用协议")
        }
  
        console.log(userinfo);
        console.log(serverhost);
        axios
          .post(serverhost + "/v1/register", userinfo)
          .then((response) => {
            alert("注册成功,可以登录");
            console.log(response);
            // this.$router.push("/taskmanager")
          })
          .catch((e) => {
            if (e.response.status == 409) {
              var data = e.response.data;
  
              var error_string = "";
  
              for (const key in data) {
                // console.log(`${key}: ${reviewalgo_covert[key]}`);data.push(reviewalgo_covert[key])
  
                error_string += key + ":" + data[key] + " ";
              }
              alert(error_string);
            } else if(e.response.status == 438){
              alert("验证码错误，请重新输入");
            }
            
            
            
            else if (e.response.status == 422) {
              alert("创建账户异常，请联系管理员");
            } else {
              alert("创建账户异常，请联系管理员");
            }
  
            e.preventDefault();
          });
      },
      toreset() {
        console.log("服务器配置读取");
        console.log(configure_options);
  
        console.log("服务器配置读取");
        console.log(configure_options);
        var env = configure_options["env"];
  
        if (this.resetemail == "" || this.resetpassword == ""||this.resetverifycode=="") {
          alert("邮件,密码,验证码不能为空");
          return;
        }
  
        console.log(configure_options["app_setting"][env]);
        var serverhost = configure_options["app_setting"][env]["serverhost"];
  
        var userinfo = {
        
          email: this.resetemail,
          password: this.resetpassword,
          verifycode:this.resetverifycode,
          clent: "clientv2",
        };
  
        if (this.repeatresetpassword != this.resetpassword) {
          alert("两次输入密码不一致");
          return;
        }
  
      
        console.log(userinfo);
        console.log(serverhost);
        axios
          .post(serverhost + "/v1/resetpass", userinfo)
          .then((response) => {
            alert("密码修改成功");
            console.log(response);
            // this.$router.push("/taskmanager")
          })
          .catch((e) => {
            if (e.response.status == 409) {
              var data = e.response.data;
  
              var error_string = "";
  
              for (const key in data) {
                // console.log(`${key}: ${reviewalgo_covert[key]}`);data.push(reviewalgo_covert[key])
  
                error_string += key + ":" + data[key] + " ";
              }
              alert(error_string);
            } else if (e.response.status == 438) {
              alert("邮件验证码不正确");
            } else {
              alert("修改异常，请联系管理员");
            }
  
            e.preventDefault();
          });
      },
    },
  });
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
  /* Form */
  .infoofslogan {
    display: flex;
    justify-content: center;
    align-items: center;
  
  }
  
  .sci-fi-text {
    font-family: 'scitext', sans-serif; 
    color: #00ff00; /* 设置绿色文本 */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* 添加文字阴影 */
    display: flex;
    align-items: center;
    word-spacing: 8px; 
  }
  
  .animated-text {
    display: inline-block;
    margin:5px;
    animation: glow 2s infinite alternate; /* 添加一个闪烁的动画效果 */
  //   letter-spacing: 2px; 
  
  }
  
  @keyframes glow {
    from {
      color: #00ff00;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    }
    to {
      color: #ff00ff; /* 设置另一种颜色 */
      text-shadow: 2px 2px 8px rgba(255, 0, 255, 0.5); /* 设置另一种文字阴影 */
    }
  }
  
  .rocket-icon {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin-left: 5px;
  }
  :root {
    --font-family-sans: "Graphik Web", "Helvetica Neue", Helvetica, Arial,
      sans-serif;
    --font-family-heading: "Graphik Web", "Helvetica Neue", Helvetica, Arial,
      sans-serif;
    --font-family-body: "Inter", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  
  .adzone{
  
    display: flex;
    flex-direction: column;
    justify-content: center;
  
  
    .font-bold {
      font-weight: 700;
      font-size: 40px;
  }
  .introtext{
   
  
      color: rgb(54 61 78);
      // text-align: center;
      // max-width: 30vw;
  }
  
  }
  
  
  body {
    height: 100%;
    width: 100%;
  }
  
  .resetverifyzone{
    display: flex;
    flex-direction: row;
  }
  .cinfo{
    font-size:12px;
  }
  #top{
    margin-bottom: 5px;
  }
  nav {
    /* position:absolute; */
    top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding-right: 8%;
  }
  nav ul li {
    list-style: none;
    display: inline-block;
    padding: 10px 25px;
  }
  nav ul li a {
    color: black;
    text-decoration: none;
    font-weight: bold;
    text-transform: capitalize;
  }
  
  nav ul li a:hover {
    color: red;
    transition: 0.4s;
  }
  
  span {
    color: red;
  }
  
  .logotitle{
    display: flex;
    flex-direction: row;
  h1.logo {
    margin-left: 40px;
    margin-bottom: 0px;
    /* left:20%; */
    /* float:left; */
    font-size: 40px;
    line-height: 40px;
  }
  
  .logoinfo{
    border-top: solid 1px blue;
    color:black;
    font-weight: 600;
  }
  
  }
  
  #userpage {
    background-color: white;
    display: flex;
    flex-direction: column;
    height: 100vh;
    
    overflow-y:auto;
    #middlezone {
      display: flex;
  
      flex-direction: row;
      justify-content: space-evenly;
      overflow-y: auto;
      #usersystem {
        position: absolute;
        // position:relative;
        top: 10%;
        right: 10%;
        bottom:0%;
        align-items: flex-end;
        // max-height: 400px;
        /* bottom:10%; */
        
      .form {
    
    // z-index: 1;
    /* 最后一个参数为透明的颜色 */
      background: rgba(3, 3, 3, 0.1);
      max-width: 300px;
      margin: 0 auto 100px; 
      padding: 10px;
     
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      text-align: center;
      
      input {
        margin-left: 10px;
      margin-right:10px;
      outline: 0;
      background: #f2f2f2;
      width: 100%;
      border: 0;
      margin: 0 0 15px;
      padding: 5px;
      border-radius: 70px;
      /* border-top-left-radius: 3px; */
      /* border-top-right-radius: 3px; */
      /* border-bottom-left-radius: 3px; */
      /* border-bottom-right-radius: 3px; */
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      font-size: 14px;
    }
    .thumbnail {
      background: #8acecb;
      width: 100px;
      height: 100px;
      margin: 0 auto 30px;
      padding: 50px 30px;
      border-top-left-radius: 100%;
      border-top-right-radius: 100%;
      border-bottom-left-radius: 100%;
      border-bottom-right-radius: 100%;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      img {
        display: block;
        width: 100%;
      }
    }
  
    button {
      outline: 0;
      background: red;
      width: 100%;
      border: 0;
      padding: 8px;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      color: #ffffff;
      font-size: 14px;
      -webkit-transition: all 0.3 ease;
      transition: all 0.3 ease;
      cursor: pointer;
    }
    .message {
      margin: 15px 0 0;
      /* color:#f01515; */
      color: #ac3434;
      font-size: 12px;
      a {
        color: #f7f7f7;
        text-decoration: none;
      }
    }
  
    .register-form {
      display: none;
    }
  
    .message a:hover {
    color: #ef3b3a;
    text-decoration: none;
  }
  }
      }
  
      $top: 20%;
      #showimage {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        position: absolute;
        top: $top;
        left: 10%;
      }
  
  
      .agreezone {
   display: flex;
   flex-direction: column;
   position:fixed;
   align-items:flex-end;
   background: #8acecb;
   width:40vw;
   height:50vh;
   overflow-y: auto;
   left:10vw;
   top:20vh;
  
   #closeagree{
    max-width:60px;
  
   }
  }
      .imageforlogin {
        max-width: 30%;
        height: auto;
      }
    }
  
    .bottomzone {
      position: absolute;
      left: 50%;
      color: black;
      bottom: 1%;
    }
  }
  
  /* Form */
  
  .container {
    // position: relative;
    z-index: 1;
    max-width: 300px;
    margin: 0 auto;
    .info {
      margin: 10px auto;
      text-align: center;
    }
    .info h1 {
      margin: 0 0 15px;
      padding: 0;
      font-size: 26px;
      font-weight: 300px;
      font-family: fangsong;
      color: #1a1a1a;
    }
    .info span {
      color: #4d4d4d;
      font-size: 10px;
    }
    .info span a {
      color: #000000;
      text-decoration: none;
    }
    .info span .fa {
      color: #ef3b3a;
    }
  }
  
  /* END Form */
  /* Demo Purposes */
  
  .logincaption {
    padding: 5px;
    // margin-left: 10px;
    // margin-right: 10px;
    color: #fff;
    border-radius: 5px;
    background: #3e12db;
  }
  
  .imgcontent {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    
  }
  
  
  
  
  .md-flex {
    display: flex !important;
  }
  
  .textBlockGutter {
    padding: 0 var(--space-7);
  }
  .py5 {
    padding-top: 3.2rem !important;
    padding-bottom: 3.2rem !important;
  }
  
  .md-size6of12 {
    width: calc(100% * (6 / 12));
    // max-width:50vw
  }
  
  .justify-center {
    justify-content: center !important;
  }
  
  .items-center {
    align-items: center !important;
  }
  
  .text-center {
    text-align: center !important;
  }
  .block {
    display: block !important;
    font-family: var(--font-family, var(--font-family-body));
    --font-size: clamp(
      var(--min-font-size) * 1rem,
      var(--intersection) * 1rem + var(--slope) * 100vw,
      var(--max-font-size) * 1rem
    );
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
  img {
    border: 0;
  }
  .flex {
    display: flex !important;
  }
  
  .rich-text_width__0GHdb {
    max-width: calc(1em * (calc(600 / 13)));
  }
  
  .sm {
    font-size: 19px;
  }
  
  .infotitle {
    display: block;
    font-family: "Graphik Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-wrap: break-word;
    color: #000000;
    text-align: left;
    font-size: 28px;
  }
  
  .footer_footer__ruM_4 {
    box-shadow: 0 -1px 3px red, 0 -2px 2px red, 0 0 2px black;
  }
  .relative {
    position: relative !important;
  }
  .py4 {
    padding-top: 2.4rem !important;
    padding-bottom: 2.4rem !important;
  }
  .blackfont {
    color: black;
  }
  
  .sb-global-gutters {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  
  .sb-global-gutters {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .sb-global-gutters {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  
  .lg-flex {
    display: flex !important;
    justify-content: space-evenly;
  }
  
  .sb-rule {
    border: 0;
    margin: 0;
  }
  .py5 {
    padding-top: 3.2rem !important;
    padding-bottom: 3.2rem !important;
  }
  
  .sb-footer-iconMarginAdjust {
    margin-left: -5px;
  }
  
  .sizeimg{
      float: left;
      width:  40vw;
      // height: 60vh;
      object-fit: cover;
  }
  
  .adnum{
    color:red
  }
  
  .adnum1{
    color:blue;
  }
  
  .emailverifyzone{
    display:flex;
    flex-direction: row;
  
  
  
    .verifybutton{
      margin-top:5px;
    color:#4772fa;
    height: 40px;
    width:80px;
    font-size: small;
  
    
    text-align: center;
    }
  }
  
  
  .footerzone {
    max-width: 100vw;
    overflow: hidden;
    .infolist {
      list-style: none;
      display: flex;
      flex-direction: column;
    }
    .infogrouptitle {
      text-align: center;
    }
  }
  
  .social_media {
    position: relative;
    left: 40%;
  }
  
  .flex {
    display: flex !important;
  }
  /* 什么 */
  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: left;
  }
  
  .thisnav {
    display: block;
  }
  
  .text-xxs {
    font-size: 1.4rem !important;
  }
  .text-xxs {
    font-size: 1.3rem !important;
  }
  .py3 {
    padding-top: 1.6rem !important;
    padding-bottom: 1.6rem !important;
  }
  .color-textBlackSoft {
    color: rgba(0, 0, 0, 0.58) !important;
  }
  
  .lg-inline-block {
    display: inline-block !important;
  }
  
  .lg-ml5,
  .lg-mx5 {
    margin-left: 3.2rem !important;
  }
  
  .evencontent {
    margin-left: 20px;
  }
  
  .infogroup {
    display: flex;
    flex-direction: column;
  
    .infogroupdetail {
      display: flex;
      flex-direction: column;
    }
  }
  .row-reverse {
    flex-direction: row-reverse;
  }
  
  
  
  imagebox{
    object-fit: fill;
  }
  
  .adpageimage{
    min-width: 50vw;
    max-width:50vw;
   
    height: auto;
   
  
    .goaltreeimg{
    padding: 10%;
    // border-radius: 80px;
  }
    
  .imagetasktree{
    padding:10%;
    // border-radius: 300px;
  }
  
  
  .smallimg {
    padding: 10%;
    // border-radius: 300px;
  }
  }
  
  
  
  
  .topnav{
    padding-right: 5%;
  }
  
  .userbutton {
    border-radius: 10px;
    position: relative;
    top: 10px;
    background: rgb(60, 58, 230);
  
    padding: 4px;
    color: white;
    font-weight: 700;
    &:hover {
      background: #aa9595;
    }
  }
  </style>